import React from "react"
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/layout';
import PostLink from "../components/post-link";


export default ( {pageContext} ) => {
    const {category, edges, node} = pageContext;
    const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .filter(edge => edge.node.frontmatter.category === category)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} active={node} />)
  return (
    <Layout >
        <div className="columns is-multiline">
            <div className="column">
                <Link to={'/'+category}>
                    <h1 className="title is-capitalized is-4">&lt; {category}</h1>
                </Link>
                <div className="is-hidden-mobile">
                    {(Posts.length)?Posts:'There seems to be nothing here... yet'}
                </div>
            </div>
            <div className="column md">
                <MDXRenderer>{node.body}</MDXRenderer>
            </div>
        </div>
    </Layout>
  )
}