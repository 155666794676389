import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default ({ post, active }) => {
  const activeStyle = (!!active && (active.id===post.id))?"has-background-dark":"";
  const linkpath = "/" + post.frontmatter.category + "/" + post.slug;
  return (
    <article className={"media "+activeStyle}>
      <figure className="media-left">
        <Link to={linkpath}>
          <span className="image is-96x96">
            <Img fixed={post.frontmatter.image.childImageSharp.fixed} alt={post.frontmatter.title} />
          </span>
        </Link>
      </figure>
      <div className="media-content">
        <div className="content">
          <p>
            <Link to={linkpath} classname="level-item">
              <strong classname="title is-5">{post.frontmatter.title}</strong>
            </Link>
            &nbsp;<small>{post.frontmatter.date}</small>
          </p>
          <p>{!!(post.frontmatter.short) ? post.frontmatter.short : post.excerpt}</p>
        </div>
        <nav class="level is-mobile is-hidden">
          <div class="level-left">
            <Link to={linkpath} classname="level-item">
              <small>read more</small>
            </Link>
          </div>
        </nav>
      </div>
    </article>
  );
}